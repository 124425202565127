export default function ContactPage() {
  return (
    <>
      <section className="innerBAnner">
        <div className="container">
          <div className="row ">
            <div className="col-12">{/* <h1>Contact</h1> */}</div>
            <div className="col-lg-6 col-xxl-4">
              {/* <p>Get in contact with us.</p> */}
            </div>
          </div>
        </div>
      </section>

      <section className="aboutPage">
        <div className="aboutLayerOne">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 order-lg-2 text-end mb-5 mb-lg-0">
                {/* <figure>
                  <img src="img/img8.png" alt="" />
                </figure> */}
              </div>
              <div className="col-lg-7 text-center text-lg-start pe-lg-5">
                <h1 className="mb-md-4">Contact</h1>
                <h2>
                  {" "}
                  <a href="mailto:hello@mapspot.com" className="text-secondary">
                    hello@mapspot.com
                  </a>
                </h2>
                <h2>
                  {" "}
                  <a
                    href="https://twitter.com/MapspotInc"
                    className="text-secondary"
                  >
                    Twitter @mapspotinc
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
