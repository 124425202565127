import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"

import Footer from "./ShareModule/Footer/Footer"
import Header from "./ShareModule/Header/Header"
import HomePage from "./PagesModule/HomePage/HomePage"
import PrivacyPolicyPage from "./PagesModule/PrivacyPolicyPage/PrivacyPolicyPage"
import TermsOfServicePage from "./PagesModule/TermsOfServicePage/TermsOfServicePage"
import AboutUsPage from "./PagesModule/AboutUsPage/AboutUsPage"
import OurMissionPage from "./PagesModule/OurMissionPage/OurMissionPage"
import FaqPage from "./PagesModule/FaqPage/FaqPage"
import ContactPage from "./PagesModule/ContactPage/contactPage"

// import { useSelector } from "react-redux";

function App() {
  const [tc, setTc] = useState(false)
  const [pp, setPp] = useState(false)
  const handleTCClick = () => setTc(!tc)
  const handlePPClick = () => setPp(!pp)

  return (
    <>
      <Router>
        <Header handleTCClick={handleTCClick} handlePPClick={handlePPClick} />
        <Switch>
          <Route exact path="/">
            <HomePage
              tc={tc}
              pp={pp}
              handleTCClick={handleTCClick}
              handlePPClick={handlePPClick}
            />
          </Route>
          <Route exact path="/about">
            <AboutUsPage
              tc={tc}
              pp={pp}
              handleTCClick={handleTCClick}
              handlePPClick={handlePPClick}
            />
          </Route>
          <Route exact path="/mission">
            <OurMissionPage
              tc={tc}
              pp={pp}
              handleTCClick={handleTCClick}
              handlePPClick={handlePPClick}
            />
          </Route>
          <Route exact path="/faq">
            <FaqPage
              tc={tc}
              pp={pp}
              handleTCClick={handleTCClick}
              handlePPClick={handlePPClick}
            />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicyPage
              tc={tc}
              pp={pp}
              handleTCClick={handleTCClick}
              handlePPClick={handlePPClick}
            />
          </Route>
          <Route exact path="/terms-of-service">
            <TermsOfServicePage
              tc={tc}
              pp={pp}
              handleTCClick={handleTCClick}
              handlePPClick={handlePPClick}
            />
          </Route>
          <Route exact path="/contact">
            <ContactPage />
          </Route>

          <Route path="*">
            <>
              <div className="text-center min-vh-100 d-flex flex-column justify-content-center bg-ass align-items-center">
                <div className="card w-auto p-4 shadow">
                  <h1>404 </h1>
                  <h6>Page not Found</h6>
                  <hr />
                  <h4>Page Under Development</h4>
                </div>
              </div>
            </>
          </Route>
        </Switch>
        <Footer handleTCClick={handleTCClick} handlePPClick={handlePPClick} />
      </Router>
    </>
  )
}

export default App
