import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import "./Footer.scss"

export default function Footer({ handleTCClick, handlePPClick }) {
  const [OpenModal, setOpenModal] = useState("none")
  const toggleModal = (data) => {
    setOpenModal(data)
  }
  const closeModal = () => {
    setOpenModal("none")
  }
  const gotoTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const openUrl = (url) => {
    window.location = url
  }
  return (
    <>
      <footer>
        <div className="menu">
          <div className="container">
            <nav>
              <ul className="list-unstyled d-flex flex-wrap mb-0 justify-content-center">
                <li>
                  <NavLink
                    to="/"
                    activeClassName="active"
                    onClick={() => gotoTop()}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faq"
                    activeClassName="active"
                    onClick={() => gotoTop()}
                  >
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <a
                    onClick={() =>
                      openUrl(
                        "https://app.termly.io/document/privacy-policy/1650689f-960f-4520-a1e9-11a5a438c2e9"
                      )
                    }
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      openUrl(
                        "https://app.termly.io/document/terms-of-use-for-ecommerce/fde63d7d-0c4e-4d6f-830b-2ded76a0e9eb"
                      )
                    }
                  >
                    Terms of service
                  </a>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="container text-ass text-center">
          <p>© 2022 Mapspot Inc. ©Mapbox ©Openstreetmap</p>
        </div>
      </footer>

      {OpenModal === "pp" && (
        <div className="customModal">
          <div className="modalSkin" onClick={() => closeModal()}></div>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h1>Privacy Policy</h1>
              <div className="closeModal" onClick={() => closeModal()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                </svg>
              </div>
            </div>
            <div className="card-body">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Labore nemo blanditiis, asperiores voluptatum autem rem totam
                  cum nihil perferendis expedita nam tempora dolorum veniam quam
                  sed dolor, dicta consequuntur dolore?
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      {OpenModal === "tos" && (
        <div className="customModal">
          <div className="modalSkin" onClick={() => closeModal()}></div>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h1>Terms of service</h1>
              <div className="closeModal" onClick={() => closeModal()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                </svg>
              </div>
            </div>
            <div className="card-body">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Labore nemo blanditiis, asperiores voluptatum autem rem totam
                  cum nihil perferendis expedita nam tempora dolorum veniam quam
                  sed dolor, dicta consequuntur dolore?
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
