const faqArray = [
  {
    question: "How does placing spots in Mapspot® benefit me?",
    answer:
      "Publicity. Every spot is unique virtual-space℠ on the map that links to your media and coorelates spatial to Earth.",
  },
  {
    question: "Why would I want to place more than one spot?",
    answer:
      "The more spots you place on the map means more potential publicity for your content as people discover your spots on the map.",
  },
  {
    question: "Why is Id verification required to place spots on the map?",
    answer:
      "To ensure each person's spots are spaced at least 2 miles apart and prevent one person from placing many spots together accross multiple accounts.",
  },
  {
    question:
      "How can I permanently delete my submitted Id verification images?",
    answer:
      "You can delete submitted Id verification images by deleting your Mapspot account in Settings - Account - Delete Account. Note that if you reinstate your account afterwards you will need to go through the Id verification process again and resubmit new Id and selfie images.",
  },
  {
    question: "Will my real name from my Id be publicly viewable?",
    answer:
      "No. only your username and display name, which you choose, are shown in the app. Information from your identification is confidential and your real identity is never revealed.",
  },
  {
    question: "How do I remove my spot/s from the map?",
    answer:
      "Tap on your spot you want to remove to reveal your profile, then tap on the upper right menu dots, tap “Remove my spot”, and enter your password. This will not modify your subscription.",
  },
  {
    question: "Can anyone change the location of my spots?",
    answer:
      "No. Only you may place or remove your own spots on the map. However, if your subscription payment lapses your spots will be removed after a 16 day grace period.",
  },
  {
    question: "Does Mapspot display my or anyone else's location?",
    answer:
      "No. Spots placed on the map only represent where users have placed spots.",
  },
  {
    question: "How much virtual-space℠ is a single spot?",
    answer: "Every spot is about a 1/2 virtual acre℠ and 100’ diameter",
  },
  {
    question: "Why can’t I place my spots closer than 2-miles apart?",
    answer:
      "Spots placed under a Mapspot account are kept separated by a minimum 2 miles to prevent anyone from clustering many spots together, which would be unfair to the community",
  },
  {
    question: "Are my spots removed from the map if there are payment issues?",
    answer:
      "You would have a 16 day grace period to resolve billing issues before your spots are automatically removed from the map.",
  },
  {
    question: "What if I downgrade my subscription?",
    answer:
      "If you downgrade outside of the app, you have 16 days after you current billing cycle ends to choose which of your spots to remove from the map so that your placed spot count is equal to or less than your subscription, otherwise our system removes the necessary amount of spots required to meet your subscription in order from your latest placed spots to the newest.",
  },

  {
    question: "What is the collect / collecting button on profiles?",
    answer:
      "As you discover other user’s profiles, you may “collect” that user so when they post new media it will appear in your post view.",
  },
  {
    question: "In what countries can I place spots?",
    answer:
      "You may place spots within the same issuing country of the account Id document. For Mapspot V1.0.0, only within the United States.",
  },
  {
    question: "Which kind of Id’s can you accept?",
    answer:
      "At this time, only state issued driver’s licenses or identification from the United States.",
  },
]

module.exports = faqArray
