import "./HomePage.scss"
import React, { useState } from "react"

export default function HomePage({ tc, pp, handleTCClick, handlePPClick }) {
  const [layer4, setlayer4] = useState(tc)
  const [layer5, setlayer5] = useState(false)

  return (
    <section className="homePage">
      <div className="layer1 ">
        <div className="container">
          <div className="row">
            <div className="col-xxxl-8 mx-auto text-center  ">
              <h1 className="text-secondary fw-9 pe-xl-5">
                Virtual space as a medium.
                <h3>How we go outside evolves.</h3>
              </h1>

              <div className="layer2">
                <div className="container">
                  <figure className="d-block m-0">
                    <img src="img/apple-app-store.png" width="5%" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="layer3">
        <div className="container">
          <div className="col-xxxl-8 mx-auto text-center"></div>
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2 py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/overlap-3.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>Unique virtual space</h3>
              <p>
                Place unique Spots℠ on the world map that correlate spatially
                with Earth and link to your Mapspot® profile. People can
                physically move through your spots, where create virtual space℠
                on Earth, and discover your media.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6   py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/claim-mode-3.png" alt="" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>Placing spots</h3>
              <p>
                The map represents all the surface area of Earth, where our
                community places spots that create geofences on Earth that act
                as a link to their media.
              </p>
              <p>
                Spots are a 1/2 virtual acre℠, 100' in diameter, and placed
                using the centered "placing tool" (pictured left).
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2 py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/popup-profile-1.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>Popup profiles</h3>
              <p>
                Spots link to popup profiles with a single tap. As the community
                discovers one another, they can collect℠ each other to stay up
                to date with new posts they create.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/activate.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>How to place spots</h3>
              <p>
                Tap the placing mode button on the map to activate the center
                placing tool, drag the map to where you want to place, if the
                placing tool does not overlap an existing spot, tap on it to
                place your new spot.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2  py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/small-spacer-field.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>
                Spacer Fields℠<p>(patent pending)</p>
              </h3>

              <p>
                Activated during placing mode, the center placing tool and spots
                use spacer fields so all spots are separated by at least 60'.
              </p>
              <p>
                Spacer fields turn red and prevent the placing of a new spot if
                overlapping with any nearby spot's spacer field.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6  py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/id-verification-5.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>Id-verifiction & rules</h3>
              <p>Mapspot® has rules to create a fair placing opportunity.</p>
              <p>
                <span>&#8226;</span> Only one Id-verified account per person.
              </p>

              <p>
                <span>&#8226;</span> Anyone may only place one spot for each
                large city.
              </p>
              <p>
                <span>&#8226;</span> All of a user's spots must be separated by
                at least 2 miles.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2 py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/profile-manage.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>Profile Management</h3>
              <p>
                Manage your profile and see notifications all on one screen. The
                upper half represents how your popup profile appears on the map
                after someone taps on one of your spots.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6  py-5">
              <figure className="m-0 d-block">
                <div className="promoImages">
                  <img src="img/newsfeed-1.png" alt="" width="55%" />
                </div>
              </figure>
            </div>
            <div className="col-lg-6 pe-lg-5 py-5">
              <h3>Newsfeed</h3>
              <p>
                After collecting℠ people you discover on the map their posts
                will appear in your post view so you can stay up to date with
                their content.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* {pp === true && (
        <div className="layer4 d-flex align-items-center px-3">
          <div className="bigClose" onClick={handlePPClick}></div>
          <div className="container py-4">
            <h2 className="text-center text-center mb-5 ">
              Data & Location Privacy
            </h2>
            <div className="row">
              <div className="col-lg-6 py-md-5">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <figure>
                      <div className="promoImages">
                        <img src="img/img6.png" alt="" width="55%" />
                      </div>
                    </figure>
                  </div>
                  <div className="col-md-6">
                    <div className="h4">Privacy based business model.</div>
                    <p className="text-ass">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-md-5">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <figure>
                      <img src="img/img7.png" alt="" width="55%" />
                    </figure>
                  </div>
                  <div className="col-md-6">
                    <div className="h4">Privacy based business model.</div>
                    <p className="text-ass">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center pt-md-5">
                <button className="btn btn-xxl btn-outline-secondary rounded-pill fw-6 ">
                  See our privacy policy
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* {tc === true && (
        <div className="layer5 d-flex align-items-center px-3">
          <div className="bigClose" onClick={handleTCClick}></div>
          <div className="container text-center py-4">
            <h2 className="mb-5 pb-md-5">Terms of Service</h2>
            <div className="row justify-content-around">
              <div className="col-lg-4 text-center">
                <div className="h4"></div>
                <p></p>
              </div>
              <div className="col-lg-4 text-center">
                <div className="h4"></div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <div className="layer6 py-5 mt-5">
        {/*}
        <div className="container text-center">
          <h2 className="mb-4 ">Frequently Asked Questions</h2>
          <div className="row justify-content-around">
            <div className="col-md-10 text-center">
              <p>
                Visit our frequently asked questions to better understand how
                Mapspot represents a new era in virtual-space.
              </p>
              <div className="pt-4 pt-md-5">
                <button className="btn btn-xxl btn-outline-secondary rounded-pill fw-6">
                  <span className="px-3">Visit our FAQ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      */}
      </div>
    </section>
  )
}
