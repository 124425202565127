// import './OurMissionPage.scss';

export default function TermsOfServicePage() {
  return (
    <>
      <section className="innerBAnner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Terms Of Service</h1>
            </div>
            <div className="col-lg-6 col-xxl-4">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
